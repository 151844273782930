$brand-primary: #003366;
$brand-secondary: #00b3bb;
$brand-green: #42ab98;
// $brand-gradient: linear-gradient(90deg, #ed6762 0%, #ea5393 100%);
$brand-gradient: linear-gradient(60deg, #34319e 0%, #00b3bb 100%);

$background-color: #fbfbfb;

$fill-grey: #0000004d;
$black-30pc: rgba(0, 0, 0, 0.3);
$black-50pc: rgba(0, 0, 0, 0.5);
$black: rgba(0, 0, 0, 0.8);

$footer-text: rgba(0, 0, 0, 0.75);

$content-border: 1px solid rgba(0, 0, 0, 0.1);
$content-border-radius: 1rem;
$border-radius: 0.5rem;

$sidebar-width: 160px;
$container-width: 992px;

$table-header: rgba(0, 0, 0, 0.08);

$margin1x: 1rem;
$padding1x: 1rem;
$font1x: 1rem;

$margin-1x: 1rem;
$padding-1x: 1rem;
$font-1x: 1rem;

$margin-half: 0.5rem;
$padding-half: 0.5rem;
$font-half: 0.5rem;

$box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

$gold-colour: #FFCB4B;
$silver-colour: #D4D6E4;
$bronze-colour: #FF9328;

$outstanding-color: #00B9C1;
$good-color: #49E5A6;
$ri-color: #FFCB4B;
$inadequate-color: #E76D93;
$na-color: #D4D6E4;