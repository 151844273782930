
@import "@/assets/styles/variables.scss";
.passwordReset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
}
