
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.search-bar {
  max-width: 400px;
}

.search-field {
  margin: 0 !important;
}

.search-field input {
  width: 90%;
  outline: none;
  border: none;
}

.input-container {
  border-bottom: 1px solid lightgrey;
  width: 100%;
}

.results-container {
  border: 1px solid lightgrey;
  border-radius: $border-radius;
}

.unassigned {
  width: 100%;
  max-height: 500px;
  min-height: 320px;
  margin-bottom: 1rem;
  overflow-y: scroll;
}
