
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;
  &__dropbox {
    align-items: center;
    border-radius: $border-radius;
    border: 1px dashed lightgrey;
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    height: 100px;
    justify-content: center;
    margin: 1rem;
    padding: 1rem;
  }
  .button-row {
    display: flex;
    flex-direction: row-reverse;
  }
}

.is-active {
  background: #42ab983b;
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.list__options {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: baseline;
  .input {
    max-width: 64px;
    padding-left: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}

.list__controls {
  grid-column: 1 / 3;
  justify-content: center;
  margin-bottom: 20px;
}

.list__controls,
.pagination-control {
  display: flex;
}
.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}

th {
  // color: red;
  cursor: pointer;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
