
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;

  &__dropbox {
    align-items: center;
    border-radius: $border-radius;
    border: 1px dashed lightgrey;
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    height: 100px;
    justify-content: center;
    margin: 1rem 0;
    padding: 1rem;
  }

  .button-row {
    display: flex;
    flex-direction: row-reverse;
  }

  .button.left {
    margin-right: auto;
  }
}

.is-active {
  background: #42ab983b;
}
