.modal {
  top: 0;
  align-items: center;
  bottom: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  z-index: 10;
  .modal-background {
    top: 0;
    background: rgba(10, 10, 10, 0.86);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .modal-content {
    background: white;
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    margin: 0;
    max-height: calc(100vh - 40px);
    overflow: auto;
    padding: 1rem;
    position: relative;
    min-width: 400px;
    .modal-title {
      border-bottom: 1px solid #0000004d;
      font-size: 1.25rem;
      font-weight: 600;
      margin: 10px 0;
      padding: 0;
      padding-bottom: 20px;
      text-align: center;
    }
    .modal-body {
      padding: 15px 0;
      .field {
        display: flex;
        flex-flow: column;
        margin-bottom: 1rem;
        label {
          font-weight: 600;
          font-size: 0.9rem;
          padding-bottom: 0.5rem;
        }
        span.icon {
          bottom: 0rem;
        }
        .select select {
          display: inline-flex;
          outline: 0;
          appearance: none;
          height: 2.5rem;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 99px;
          color: #363636;
          width: 100%;
          // min-width: 150px;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          padding: 0.25rem 1rem;
          line-height: 1.5;
          padding-left: 3rem;
        }
      }
    }
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      // flex-flow: column;
    }
  }
  &.is-active {
    display: flex;
  }
}
