.plan-container,
.section-container,
.comments-container {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
}

.comments-container {
  grid-template-columns: 1fr;
  grid-template-rows: 5rem min-content;
  grid-template-areas:
    'title search'
    'main main'
    'results results';
}

.plan-container {
  &__select {
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 2.5rem auto;
    grid-template-areas:
      'title search'
      'main main';
  }

  &__confirm {
    grid-template-columns: 1fr;
    grid-template-rows: 30px 16px auto;
    grid-template-areas:
      'title'
      'subtitle'
      'main';
  }

  &__summary {
    grid-template-columns: 1fr;
    grid-template-rows: 40px 16px auto;
    grid-template-areas:
      'title'
      'subtitle'
      'main';
  }

  &__user-mgmt {
    grid-template-columns: 1fr;
    grid-template-rows: 2.5rem min-content;
    grid-template-areas:
      'title search'
      'main main';
  }

  &__assign-audit {
    grid-template-columns: 1fr;
    grid-template-rows: 2.5rem min-content;
    grid-template-areas:
      'title search'
      'main main';
  }

  &__reports {
    grid-template-columns: 1fr;
    grid-template-rows: 2.5rem 1fr;
    grid-template-areas:
      'title'
      'reports';
  }

  &__query-builder {
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
  }
}

.section-container {
  // padding: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto) min-content 1fr 2rem;
  grid-row-gap: 0.5rem;
  grid-template-areas:
    'title'
    'subtitle'
    'grades'
    'comments'
    'evaluation'
    'buttonrow';
  .grades-row {
    grid-area: grades;
    grid-auto-flow: column;
    display: grid;
    grid-auto-columns: minmax(19%, 24%);
    grid-template-rows: 1fr;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    .selected {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }
    .not-selected {
      opacity: 0.3;
    }
  }
  .textarea {
    grid-area: comments;
    border-radius: $content-border-radius;
    border: $content-border;
    height: 100%;
    font-size: 1rem;
    outline: none;
    resize: none;
    overflow: auto;
    padding: 10px;
  }
  textarea,
  textarea::placeholder {
    font-family: AllRoundGothicBook;
  }
  .evaluation {
    display: grid;
    grid-area: evaluation;
    grid-template-rows: 1.25rem 1fr;
    row-gap: 1rem;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'subtitle link'
      'criteria criteria';
    justify-content: space-between;
    overflow-y: scroll;
  }
  .button-row {
    grid-area: buttonrow;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: space-between;
    align-items: center;
    .info-row {
      grid-area: unset;
    }
  }
  .accordion-container {
    grid-area: criteria;
    border-radius: $border-radius;
    border: $content-border;
    overflow: auto;
  }
}

.section-container.extra-row {
  grid-template-rows: repeat(3, auto) 4rem min-content 1fr 2rem;
  grid-template-areas:
    'title'
    'subtitle'
    'grades'
    'comments'
    'criteria'
    'evaluation'
    'buttonrow';
}

.select-critera {
  grid-area: critera;
}

.section-tip {
  color: #42ab98;
  display: flex;
  flex-wrap: nowrap;
  // position: relative;
  // flex-flow: row;
  // align-items: center;
  // justify-content: flex-end;
  span {
    display: flex;
    flex: 1 1 50%;
    place-items: center;
    place-content: center;
  }
  p {
    padding-left: 4px;
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }
}

.tooltip {
  position: absolute;
  // right: 1rem;
  // left: 1rem;
  // top: 2rem;
  // max-width: 350px;
  width: 100%;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background: white;
  padding: $padding1x;
  z-index: -1;
  opacity: 0;
  color: #42ab98;
  font-weight: 600;
  transition: all 500ms ease;
  letter-spacing: 0.075rem;
}

.tooltip.visible {
  z-index: 1;
  opacity: 1;
}

.title,
.subtitle {
  grid-area: title;
  margin: 0;
  font-weight: 600;
  font-size: 1.25rem;
  padding-left: 1rem;
}

.subtitle {
  grid-area: subtitle;
  font-size: 1rem;
  font-weight: 400;
  &--bold {
    font-weight: 600;
  }
}

.search-bar {
  grid-area: search;
}

// .search-bar.field {
//   height: 100%;
//   margin-bottom: 0 !important;
// }

.search-bar.field > span.input-icon {
  height: 100%;
}

.plan-list {
  grid-area: main;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'table table'
    'inforow inforow';
  width: 100%;
  height: 100%;
  &__home {
    grid-template-rows: min-content repeat(2, auto);
    grid-template-areas:
      'liveplan liveplan'
      'newplan newplan'
      'inforow inforow';
  }
}

.list-description {
  padding-bottom: 0.5rem;
}

.list-divider {
  margin: 1rem;
  margin-bottom: 0;
  border-top: 1px solid #eaeaea;
}

.table-wrapper {
  grid-area: table;
  padding-top: $padding1x;
  // box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
  &__live {
    grid-area: liveplan;
  }
  &__new {
    grid-area: newplan;
  }
}

.info-row {
  display: grid;
  grid-area: inforow;
  justify-content: start;
  align-content: flex-end;
  &--center {
    text-align: center;
  }
  &--right {
    justify-content: end;
    text-align: right;
  }
  &--flush {
    padding-top: $padding1x;
    justify-content: end;
    text-align: right;
    align-content: flex-start;
  }
}

.flow-modal {
  position: absolute;
  height: 100%;
  width: 100%;
}

.diagram {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .img {
    height: 90%;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    transition: all 250ms ease;
    cursor: pointer;
  }
  .img:hover {
    transform: scale(1.15);
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  }
}
