input,
input::placeholder {
  font-family: AllRoundGothicBook;
}

input:focus,
input:active {
  outline: none;
  border-radius: 6px;
}

.field {
  position: relative;
  width: 100%;
}

.input {
  height: 2.5rem;
  line-height: 1.5rem;
  background-color: white;
  border: 1px solid $black-30pc;
  border-radius: 99px;
  font-size: $font1x;
  max-width: 100%;
  width: 90%;
  transition: border-radius 150ms ease-in-out;
}

.input.checkbox {
  width: 40px;
}

.input.has-icon {
  padding-left: 2.5rem;
  padding-right: 10px;
  box-sizing: border-box;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.input-icon {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 4px;
  i,
  svg {
    font-size: 1.25rem;
    color: $black-30pc;
    fill: $fill-grey;
  }
  .fa-eye, .fa-eye-slash {
    transition: all 0.5s;
    font-size: 1.5rem;
    &:hover{
      color: black;
    }
  }

}
.icon-end{
    cursor: pointer;
    right: 0;
 
}



p.link {
  user-select: none;
  color: $brand-primary;
  margin-top: $margin1x;
  a,
  i {
    cursor: pointer;
    margin-right: 10px;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  &--back {
    color: $black-50pc;
  }
}

.link.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  font-weight: 400;
}

.table {
  border-collapse: collapse;
  border-radius: $content-border-radius;
  border: $content-border;
  overflow: hidden;
  width: 100%;
}

.table tr {
  height: 2rem;
}

.table td,
.table th {
  text-align: center;
  // padding: 4px;
}

.table tbody {
  font-size: 0.85rem;
  font-weight: 600;
}

.table thead {
  background: $table-header;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
}

.table tr:nth-child(even) {
  background: #f8f8f8;
}

.table tr td:hover {
  cursor: pointer;
}

.table tr.selected {
  background: rgba(234, 82, 150, 0.5);
  // box-shadow: $box-shadow;
}

.button {
  padding: 0.66rem 1rem;
  background: $brand-primary;
  border: none;
  border-radius: 99px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  cursor: pointer;

  &:disabled {
    background-color: rgba(234, 82, 150, 0.5);
    cursor: not-allowed;
  }
}

.button.secondary {
  background: unset;
  color: $black;
  text-decoration: unset;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    color: $black-30pc;
  }
}

.button {
  &__list-item {
    padding: 0.5rem;
    background: $brand-secondary;
    border: none;
    border-radius: 4px;
    color: white;
    box-shadow: none;
    transition: box-shadow 300ms ease-in-out;

    &__true {
      background-color: green;
    }
    &__false{
      background-color: red;
    }
    &__true , &__false {
      color: white;
      outline: none;
      border: none;
      border-radius: 4px;
      padding: 5px 10px;
      cursor: pointer;
      transition: box-shadow 300ms ease-in-out;
      &:disabled{
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
      &:hover {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    }
  }
}

.graph-container {
  grid-area: reports;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  grid-auto-flow: row;
  grid-auto-rows: 100%;
  column-gap: 10px;
  row-gap: 10px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.graph-report {
  position: relative;
  max-width: 100%;
}

.graph-options {
  grid-area: options;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 1rem;
  .option-radio {
    padding: 0.5rem;
    label {
      margin-left: 0.5rem;
    }
  }
}

.table tr {
  height: 3rem;
}
